import React, { useState } from "react"
import AuctionView from "./style"
import { Col, Container, Row } from "react-bootstrap"
import Countdown from "react-countdown";
import { queryClient } from "../../../App"
import NoDataFound from "../../Reuseable/ErrorPage/NoDataFound"
import Loading from "../../Reuseable/Loading/Loading"
import aus from "../../../assets/images/icons/australia.svg"
import Lot from "./Lot";
import { useOutletContext } from "react-router-dom";

const LiveAuction = () => {

  const { isFirstTime, noDataFound, isLoading, refetch, liveData } = useOutletContext()
  const [hour, setHour] = useState(0)

  const doAfterCompletion = () => {
    queryClient.setQueriesData(["getLiveAuctionData"], null)
    refetch()
  }

  const hoursRenderer = ({ hours }) => {
    setHour(hours)
    return (
      hours
    )
  }
  const secondsRenderer = ({ seconds }) => {
    return (
      seconds
    )
  }
  const minutesRenderer = ({ minutes }) => {
    return (
      minutes
    )
  }

  const isNoDataFound = () => {
    return (noDataFound || liveData === null || liveData === undefined) ? true : false
  }

  const checkBoolean = (value) => {
    return value === "yes" ? true : false
  }

  return (
    <React.Fragment>
      {isNoDataFound() ? isLoading && isFirstTime.current ? <Container className="mt-5"> <Loading /> </Container>
        : <Container className="mt-5"> <NoDataFound /> </Container> : (
        <AuctionView>
          <div className="container-wrapper mt-5">
            <Container className='main-head p-0 bg-white mb-5'>
              <Row className="count-down-row">
                <Col md={9}>
                  <p className="live-auction">
                    <div>Live Auction</div>
                    {checkBoolean(liveData?.interstate_auction) ? <div className="interstate">
                      <img className="interstate_icon" src={aus} alt="interstate" />
                      <div className="interstate_text">
                        This auction is available for interstate broker!
                      </div>
                    </div> :
                      <></>}

                  </p>
                </Col>
                <Col md={3}>
                  <Row className="justify-content-evenly">
                    <div className="time-remaining">Time Remaining</div>

                    {hour !== 0 && (
                      <Col className="first-line timer-col" md={6}>
                        <p className="live-p">
                          <Countdown key={new Date(liveData?.bid_end_time)} date={new Date(liveData?.bid_end_time)} renderer={hoursRenderer} onComplete={doAfterCompletion} /><span className="min">hours</span>
                        </p>
                      </Col>
                    )}

                    <Col className="first-line timer-col" md={6}>
                      <p className="live-p">
                        <Countdown key={new Date(liveData?.bid_end_time)} date={new Date(liveData?.bid_end_time)} renderer={minutesRenderer} onComplete={doAfterCompletion} /><span className="min">min</span>
                      </p>
                    </Col>

                    <Col className="first-line timer-col" md={6}>
                      <p className="live-p">
                        <Countdown key={new Date(liveData?.bid_end_time)} date={new Date(liveData?.bid_end_time)} renderer={secondsRenderer} /><span className="sec">sec</span>
                      </p>
                    </Col>

                  </Row>
                </Col>

              </Row>
            </Container>
          </div>

          <div className='spacing-from-head'>
            {null}
          </div>

          {liveData?.leads?.map((live, index) => (
            <Lot key={index + 1} index={index} live={live} />
          ))
          }

        </AuctionView>)
      }
    </React.Fragment >
  )
}
export default LiveAuction;