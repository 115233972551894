import React,{useState,useEffect} from 'react';
import {EditorState,convertToRaw, ContentState,convertFromHTML} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import axios from 'axios';
import EditorWrap from './style.js';

export const WysiwygEditor = ({value,onChange,changed}) => {
    let editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(value))
    );
    const [description, setDescription] = useState(editorState);

    useEffect(()=> {
      if(draftToHtml(convertToRaw(editorState.getCurrentContent()))!== draftToHtml(convertToRaw(description.getCurrentContent())))
        setDescription(editorState);
   },[changed]);

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  

    const  uploadImageCallBack = async (file) => {
           let data =  await toBase64(file);
          
            axios({
              url : 'https://trackingapitestupload.hashching.org/upload/TRHG/TRHG1/'+file.name,
              method : "POST",
              headers : {
                'content-type' : 'image/png'
              },
              data : data
            }).then((res) => {
               console.log(res);
               
            },
            (err) => {
              console.log(err);
              
            })

        // return new Promise(
        //   (resolve, reject) => {

        //     const xhr = new XMLHttpRequest();
        //     xhr.open('POST', 'https://trackingapitestupload.hashching.org/upload/TRHG/TRHG1/'+file.name);
        //     const data = new FormData();
        //     data.append('image', file);
        //     data.append('headers', {
        //       'content-type' : 'image/png'
        //     })
        //     xhr.send(data);
        //     xhr.addEventListener('load', () => {
        //       const response = JSON.parse(xhr.responseText);
        //       console.log(response)
        //       resolve(response);
        //     });
        //     xhr.addEventListener('error', () => {
        //       const error = JSON.parse(xhr.responseText);
        //       console.log(error)
        //       reject(error);
        //     });
        //   }
        // );
      }

    return(
      <EditorWrap>
       <Editor
         editorState={description}
         toolbarClassName="toolbarClassName"
         wrapperClassName="wrapperClassName"
         editorClassName="editorClassName"
         onEditorStateChange={(editorState) => setDescription(editorState)}
         onChange={e => onChange(draftToHtml(convertToRaw(description.getCurrentContent())))}
         toolbar={{
          options: ['inline', 'blockType','colorPicker', 'list', 'link', 'emoji', 'textAlign', 'history'],
            list: { inDropdown: true },
            textAlign : {  inDropdown: true }
          //  image: { uploadCallback: uploadImageCallBack, alt: { present: false, mandatory: false }},
          }}
        />
       </EditorWrap> 
    );
    
}