import { ActionTypes } from "./actions";

const setResult = (result) => {
  return {
    type: ActionTypes.SET_RESULT,
    payload: result,
  };
}

const setToBeFiltered = (toBeFiltered) => {
  return {
    type: ActionTypes.SET_TOBE_FILTERED,
    payload: toBeFiltered,
  };
}

const setReportFilter = (reportFilter) => {
  return {
    type: ActionTypes.SET_REPORT_FILTER,
    payload: reportFilter,
  };
}

const setDateRange = (dateRange) => {
  return {
    type: ActionTypes.SET_DATE_RANGE,
    payload: dateRange,
  };
}

const resetGlobal = () => {
  return {
    type: ActionTypes.RESET,
  };
}

export { setResult, setToBeFiltered, setReportFilter, setDateRange, resetGlobal };
