import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Col } from "react-bootstrap";
import { Forms } from '.';
import { userLogin } from "../../../redux/Auth/actionCreator";
import { api } from '../../../Services/api';
import { Validators } from "../../../Utilities/validator";
import { Link, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import BidleadLogo from "../../../assets/images/icons/BidLead.svg"

const LoginForm = ({ props }) => {

  const [invalidUser, setInvalidUser] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changeInForm, setChangeInForm] = useState(false);
  let navigate = useNavigate()

  const SubmitLogin = async (form, valid) => {
    setInvalidUser(null);
    if (valid) {
      setLoading(true);
      form.password = form.password.trim();
      const obj = {

        email: form.email,
        password: form.password,
        remember_me: true
      }

      try {
        const response = await api.post(props.api, obj)

        if (response.status === 200) {
          
          const userRole = response.data.accessToken ?
          jwtDecode(response.data.accessToken)?.role ? jwtDecode(response.data.accessToken)?.role : "Admin"
          : "Admin"
          
          console.log("SubmitLogin  userRole-->", userRole);
          
          let result = {
            isLoggedIn: true,
            authToken: response.data.accessToken,
            user: userRole
          }
          setSubmitted(true);
          dispatch(userLogin(result));
          setLoading(false);
          userRole === "Admin" ? navigate('/home/pre-bidding') : navigate('/home/auction')
        }
      } catch (error) {
        if (error.response) {
          setInvalidUser(error.response.data.message);
        } else if (error.request) {
          console.log('[SubmitLogin] error request-->', error.request);
        } else {
          console.log('[SubmitLogin] error message-->', error.message);
        }
        setLoading(false);
      }
    }
  };

  return (
    <div className="loginFormScreens">
      <img className="logoimg" src={BidleadLogo} alt="BidLead logo" />
      {props.tag.map((t1) => {
        return <h5 className="head fw-bold fs-5 mt-3 mb-0 text-center"> {t1} </h5>
      })}
      {invalidUser ? <Alert className="m-2 p-1 text-center" variant="danger"> {invalidUser} </Alert> : <></>}
      <Forms title={""} formArr={[
        {
          label: "Email",
          name: "email",
          type: "email",
          placeholder: "Enter your email ID or phone number",
          validators: [
            { check: Validators.required, message: "This Field is required" },
            { check: Validators.email, message: "Email is not Valid" }
          ],
          error: false,
          errormsg: "",
          value: "",
          show: true
        },
        {
          label: "Password",
          name: "password",
          type: "password",
          placeholder: "Enter your Password",
          validators: [
            { check: Validators.required, message: "This Field is required" }
          ],
          error: false,
          errormsg: "",
          value: "",
          show: true
        }
      ]}
        submitBtn={"Login"}
        formlinks={
          props.user && <Link to='//www.hashching.com.au/forgot-password' className="forgotLink">Forgot password?</Link>
        }
        onSubmit={SubmitLogin} loading={loading} submitted={submitted} changeInForm={changeInForm} setChangeInForm={setChangeInForm}
      />
      {props.user ? (<p className="account text-center">
        <Col>
          <div className="signup" ><p> Don't have an account? <Link className="hover-danger" to="//www.hashching.com.au/broker/broker-registration">Signup</Link></p></div>
        </Col>
      </p>) : <></>}
    </div>
  )
}

export default LoginForm;