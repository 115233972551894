import { Modal, Row, Col, Container } from 'react-bootstrap'
import moment from "moment-timezone"
import React from 'react'
import styled from 'styled-components';
import UIModal from '..';

const QualifyingInformaionWrap = styled.div`

.qualifing_information_title {
    color: var(--orange-color);
    font-size: 16px;
    margin-left: 0.7rem;
}
`
const QualifyingInformation = ({ ...props }) => {
    return (
        <QualifyingInformaionWrap>
            <UIModal
                {...props}
                size="lg"
                header={
                    <QualifyingInformaionWrap>
                        <Modal.Title
                            className='fw-bold fs-5 pt-3 pb-0 qualifing_information_title'
                            id="Qualifying Information Modal">
                            Qualifying Information
                        </Modal.Title>
                    </QualifyingInformaionWrap>
                }
            >
                <Container>
                    <Row>
                        <Col className="my-2">
                            <span className='fw-bold pe-2'>Enquiry Type:</span>
                            <span>{props.enquiry_type || "not provided"}</span>
                        </Col>
                        <Col className="my-2">
                            <span className='fw-bold pe-2'>Property value:</span>
                            <span>${parseInt(props.property_value).toLocaleString("en-US") === "NaN" ? "0" : parseInt(props.property_value).toLocaleString("en-US") || "0"}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="my-2">
                            <span className='fw-bold pe-2'>Household income:</span>
                            <span>${parseInt(props.household_income).toLocaleString("en-US") === "NaN" ? "0" : parseInt(props.household_income).toLocaleString("en-US") || "0"}</span>
                        </Col>
                        <Col className="my-2">

                            <span className='fw-bold pe-2'>Employment type:</span>
                            <span>{props.employment_type || "not provided"}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="my-2">
                            <span className='fw-bold pe-2'>Deposit amount:</span>
                            <span>${parseInt(props.deposit_amount).toLocaleString("en-US") === "NaN" ? "0" : parseInt(props.deposit_amount).toLocaleString("en-US") || "0"}</span>
                        </Col>
                        <Col className="my-2">
                            <span className='fw-bold pe-2'>Best time to contact:</span>
                            <span>{moment.utc(props.best_time_to_contact).tz(moment.tz.guess()).format('DD-MM-YYYY hh:mm a')}</span>
                        </Col>
                    </Row>
                    <Row>
                        <div className='fw-bold fs-5 pt-3'>Notes: </div>
                        <Col>
                            <div className='border rounded p-3'>
                                {props.notes || <span>Not Provided.</span>}
                            </div>
                        </Col>
                    </Row>
                </Container>

            </UIModal>
        </QualifyingInformaionWrap>

    )
}

export default QualifyingInformation