import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";


const AuthProtectedRoute = () => {
  const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);

  return (
    isLoggedIn ? <Outlet /> : <Navigate to={'/admin'} />
  );
};

export default AuthProtectedRoute;
