import React from "react"
import { NotificationReportWrap } from "./style"
import Tables from "../../Reuseable/Tables"
import { useFetch } from "../../../Hooks/useFetch"
import { queryClient } from "../../../App"
import { lambdaUrl } from "../../../Services/api"
import moment from "moment"

const NotificationReport = () => {
	// 	const onSuccess = (data) => {
	// 		console.log("[NotificationReport]  data -->", data)
	// 	}
	//
	// 	const onError = (error) => {
	// 		console.log("[NotificationReport]  error -->", error)
	// 	}

	const { data: NotificationReportData, isLoading } = useFetch(
		`getNotificationReport`,
		`${lambdaUrl}/6_notification-log`,
		() => {},
		() => {},
		false,
		!queryClient.getQueryData([`getNotificationReport`])
	)

	const tbodyData = NotificationReportData?.data

	const tbody = (
		<tbody>
			{tbodyData?.length > 0 &&
				tbodyData?.map((row, i) => {
					return (
						<tr key={i + 1 + ")" + row?.UniqueID}>
							<td>{row?.BrokerID}</td>
							<td>{row?.BrokerName}</td>
							<td>
								{moment(row?.DateAndTime).format("YYYY-MM-DD hh:mm:ss a")}
							</td>
							<td>{row?.NotificationStatus}</td>
							<td>{row?.NotificationType}</td>
							<td>{row?.StateCode}</td>
							<td>{row?.StateName}</td>
						</tr>
					)
				})}
		</tbody>
	)

	const columns = [
		"Broker ID",
		"Broker Name",
		"Date",
		"Notification Status",
		"Notification Type",
		"State Code",
		"State Name",
	]
	const extra = null
	const header = (
		<div className=''>
			<h2 className='mt-3 mb-0'>Notification Report</h2>
		</div>
	)

	const tableData = {
		header,
		loading: isLoading,
		notfound: "No data found",
		extra,
		columns,
		tbodyData,
		tbody,
		headerNoWrap: false,
	}

	return (
		<NotificationReportWrap>
			<Tables {...tableData} />
		</NotificationReportWrap>
	)
}

export default NotificationReport
