import React, { useState, useEffect } from 'react'
import AlertMessage from './AlertMessage';
import './style.css';
import { Outlet } from "react-router-dom";
import { useContext } from "react";
import { isOnlineContext } from "../../../App";


const NoInternet = () => {
    const [show, setShow] = useState(false);
    const { online } = useContext(isOnlineContext);

    useEffect(() => {
        online ? setShow(false) : setShow(true)
    }, [online])

    return (
        <>
            <div className='NoInternetAlert'>
                {show ? <AlertMessage
                    heading={"Oh snap! You got an error!"}
                    para={"No Intenet Connection, Check your Connectino and try again"} /> : <></>}
            </div>
            <Outlet />
        </>
    )
}


export default NoInternet;