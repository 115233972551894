import { combineReducers } from "redux";
import { LayoutReducer } from "./Layout/reducers";
import { AuthReducer } from "./Auth/reducers";
import { GlobalReducer } from "./Global/reducers";

const rootReducers = combineReducers({
  Layout: LayoutReducer,
  Auth: AuthReducer,
  Global: GlobalReducer,
});

export default rootReducers;
