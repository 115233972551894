import styled from "styled-components";

const defaultFilterLabelFont = 'normal normal 600 16px Montserrat';
const black = '#000';

export const SideBarWrap = styled.div`
.tool_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.filter {
    font: normal normal bold 24px/29px Montserrat;
    color: var(--orange-color);
}
.toggle_icon_button {
    padding: 2px;
}
.MuiRadio-root {
    color: #CECECE !important;
}
.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}
.side_bar_container {
    position: fixed;
    width: 160px;
    height: 100vh;
    border-right: 1px solid #ddd;
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.menu_item {
    font: ${defaultFilterLabelFont};
    color: ${black};
    padding: 12px !important;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    &:hover {
        background-color: #ddd;
    }
}
.menu_item:last-child {
    border-bottom: none;
}
.menu_item.active {
    background-color: var(--orange-color);
    color: #fff;
}
.filter_item_label {
    font-weight: 500;
    margin-bottom: 4px;
    font: ${defaultFilterLabelFont};
    color: ${black};
}
`