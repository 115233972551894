import styled from "styled-components";

export const StartAuctionWrap = styled.div`

    .interstate_broker {
        font: normal normal 900 20px Montserrat;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: .9rem;
        color: #000000;
    }
    label {
        margin-right: 0px;
    }
    .start_auction_interstate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .interstate_checkbox {
        color: var(--orange-color) !important;
    }
    .start_auction_container {
        border-top: 1px solid #dee2e6;
    }
    .auction_confirmation {
        font: normal normal 600 20px Montserrat;
        color: var(--orange-color);
    }
    .invite_interstate_label {
        font: normal normal 600 16px Montserrat;
        color: #000000;
    }
    .btn_row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.2rem;
    }
    .yes_btn {
        background: var(--orange-color);
        border: none;
        font: normal normal 600 16px Montserrat;
        padding: 6px;
        flex: 0 0 100px;
        text-align: center;
        letter-spacing: 1.5px;
    }
    .no_btn {
        background: var(--orange-color);
        border: none;
        font: normal normal 600 16px Montserrat;
        padding: 6px;
        flex: 0 0 100px;
        text-align: center;
        letter-spacing: 1.5px;
    }
`;