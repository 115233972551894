import { useQuery } from "@tanstack/react-query"
import { api } from "../Services/api"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { userLogout, userSessionTimeout } from "../redux/Auth/actionCreator"
import { resetGlobal } from "../redux/Global/actionCreator"

export const useFetch = (queryKey, url, onSuccess, onError, interval = false, enabled = true, select) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return useQuery(
        [queryKey],
        async () => {
            return await api.get(url, {
                headers: {
                    "authorizationToken": localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    return res.data
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        // 403 is the status code for session timeout
                        dispatch(userLogout())
                        dispatch(userSessionTimeout(true))
                        dispatch(resetGlobal())
                        navigate("/login")
                    }
                })
        },
        {
            refetchInterval: interval,
            onSuccess,
            onError,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            enabled,
            select,
        })
}