import { Row, Container, Table } from 'react-bootstrap'
import moment from "moment"
import Loading from '../../Loading/Loading';
import UIModal from '..';
import styled from 'styled-components';

const ViewAllWrap = styled.div`

.view_all_container {
    overflow-x: auto !important;
    height: auto;
    max-height: 85vh !important;
}
table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1; 
    background: #fff; 
}

`
const ViewAll = ({ show, onHide, viewAllData, viewAllLoading }) => {
    return (
        <UIModal
            show={show}
            onHide={onHide}
            size="lg"
        >
            {viewAllLoading ? <Loading /> : (
                <ViewAllWrap>
                    <Container className='view_all_container'>
                        <Row>
                            <Table hover borderless>
                                <thead className='positon-fixed'>
                                    <tr className='text-center fw-bold'>
                                        <th className='text-nowrap'>Serial.No </th>
                                        <th className='text-start text-nowrap'>Broker Name</th>
                                        <th className='text-nowrap'>Bid Type</th>
                                        <th className='text-nowrap'>Bid Date Time</th>
                                        <th className='text-nowrap'>Bid Amount</th>
                                        <th className='text-nowrap'>Status</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>

                                    {viewAllData && viewAllData.map((oneData, index) => {
                                        return (
                                            <tr key={oneData.broker_name} className='border-bottom'>
                                                <td clasName="text-nowrap">{index + 1}</td>
                                                <td className="text-start text-nowrap">{oneData.broker_name}</td>
                                                <td clasName="text-nowrap">{oneData.bid_type === "auto" ? "Auto Bid" : oneData.bid_type === "manual" ? "Manual Bid" : ""}</td>
                                                <td clasName="text-nowrap">{moment(oneData.bid_date_time).format("DD-MM-YYYY, h:mm:ss a")}</td>
                                                <td className="fw-bold text-nowrap">${oneData.bid_amount}</td>
                                                <td className="text-nowrap">{oneData.status}</td>
                                            </tr>
                                        )
                                    })}
                                    
                                </tbody>
                            </Table>
                        </Row>
                    </Container>
                </ViewAllWrap>)}
        </UIModal>
    )
}

export default ViewAll
