import { styled } from '@mui/material';
import React from 'react'
import MuiDrawer from '@mui/material/Drawer';
import { drawerWidth } from '../../../../config/constant';
import { SideBarWrap } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { setResult } from '../../../../redux/Global/actionCreator';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            height: 'calc(100vh - 64px)',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            border: 'none',
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(6),
                },
            }),
        },
    }),
);

const SideBar = ({ open }) => {


    const result = useSelector(state => state.Global.result)
    const dispatch = useDispatch()


    const handleResult = (res) => {
        dispatch(setResult(res));
    };

    const activeSidebar = result === "won" ? "won" : result === "failed" ? "failed" : "notification"

    return (
        <SideBarWrap open={open}>
            <Drawer variant="permanent" open={open}>

                <div className='side_bar_container'>
                    <div className={`menu_item ${activeSidebar === "won" ? "active" : ""}`} onClick={() => handleResult("won")}>
                        Bid Won
                    </div>

                    <div className={`menu_item ${activeSidebar === "failed" ? "active" : ""}`} onClick={() => handleResult("failed")}>
                        Bid Failed
                    </div>

                    <div className={`menu_item ${activeSidebar === "notification" ? "active" : ""}`} onClick={() => handleResult("notification")}>
                        Notification
                    </div>
                </div>

            </Drawer>
        </SideBarWrap>
    )
}

export default SideBar