import { Button, Form, Spinner } from 'react-bootstrap'
import { SendToQueueWrap } from './style'
import { api } from '../../../../Services/api';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useForm } from "react-hook-form"
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup"

const schema = yup.object().shape({
    bidAmount: yup
        .number()
        .positive()
        .integer()
        .required("Enter Valid Amount")
        .typeError("Enter Amount")
})

const SendToQueue = ({ ...props }) => {

    const [confirmLoader, setConfirmLoader] = useState(false)
    const authToken = useSelector((state) => state.Auth.authToken)

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange",
        defaultValues: {
            bidAmount: ""
        },
        resolver: yupResolver(schema),
        shouldUnregister: true,
    })

    const postData = async (amount) => {
        let data = JSON.stringify({
            application_id: props.application_id,
            bid_base_amount: amount
        })
        try {
            const response = await api.post("/bid-leads/send-queue", data, {
                headers: {
                    authorizationtoken: authToken
                }
            })
            console.log("[postData] response-->", response)
            props.handleResponse("success", response.data.message)
            props.refetch()
            // ? calling the get method for the Prebid, after posting the bid 
            setConfirmLoader(false);

        } catch (error) {
            error?.response?.status === 401 ? props.handleResponse("info", error.response?.data?.message) : props.handleResponse("error", error.response?.data?.message)
            setConfirmLoader(false)
        }
    }

    const onSubmit = (e) => {
        setConfirmLoader(true)
        props.handleClick()
        postData(parseInt(e.bidAmount))
    }

    const normalizeNumber = (value) => {
        return value.replace(/[^0-9]/g, '')
    }

    return (
        <SendToQueueWrap>
            <Form noValidate className='d-flex flex-column align-items-center p-0 text-center' onSubmit={handleSubmit(onSubmit)}>
                <Form.Control
                    type="text"
                    className="text-center start-bid"
                    placeholder="$0"
                    name="bidAmount"
                    id="bidAmount"
                    maxLength="4"
                    {...register("bidAmount", {
                        valueAsNumber: true,
                        onChange: (event) => {
                            const { value } = event.target
                            event.target.value = normalizeNumber(value)
                        }
                    })}
                    isInvalid={errors.bidAmount}
                    onFocus={(e) => e.target.placeholder = ""}
                />

                {errors.bidAmount?.message &&
                    <Form.Control.Feedback type="invalid">
                        {errors.bidAmount?.message}
                    </Form.Control.Feedback>}
                <SendToQueueWrap>
                    <Button
                        className='align-self-center p-1 m-2 confirm_button'
                        id='confirm'
                        size='sm'
                        type="submit"
                    >
                        {confirmLoader ? <Spinner className='spinner' animation="border" variant="light" /> : "Send To Queue"}
                    </Button>
                </SendToQueueWrap>
            </Form>
        </SendToQueueWrap >
    )
}

export default SendToQueue