import React, { useState, useEffect } from "react"
import { Button, Container } from "react-bootstrap"
import Prebidwrap from "./style"
import Tables from "../../Reuseable/Tables"
import { api } from "../../../Services/api"
import QualifyingInformation from "../../Reuseable/UIModal/QualifyingInformation"
import CancelAuction from "../../Reuseable/UIModal/CancelAuction"
import { useSelector } from "react-redux"
import StartAuction from "../../Reuseable/UIModal/StartAuction"
import Loading from "../../Reuseable/Loading/Loading"
import NoDataFound from "../../Reuseable/ErrorPage/NoDataFound"
import { Alert, Snackbar } from "@mui/material"

const LeadQueuedForAuction = () => {
  const [cancelAuctionModal, setCancelAuctionModal] = useState(false)
  const [cancelAuctionData, setCancelAuctionData] = useState({})
  const [startAuctionModal, setStartAuctionModal] = useState(false)
  const [qualifyModalShow, setQualifyModalShow] = useState(false)
  const [qualifyModalData, setQualifyModalData] = useState("")
  const [queueBidData, setqueueBidData] = useState([""])
  const [queueBidId, setQueueBidId] = useState("")
  const [loading, setLoading] = useState(true)
  const [noDataFound, setNoDataFound] = useState(false)
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState("")
  const [alertmsg, setAlertMsg] = useState("")
  const authToken = useSelector((state) => state.Auth.authToken)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setSeverity("")
    setAlertMsg("")
    setOpen(false)
  }

  const handleResponse = (severity, responseMsg) => {
    setSeverity(severity)
    setAlertMsg(responseMsg)
  }

  const getQueuedBid = async () => {
    try {
      const response = await api.get("/bid-leads/queued", {
        headers: {
          authorizationtoken: authToken,
        },
      })
      console.log("[getQueuedBid] response-->", response)
      setqueueBidData(response.data.leads)
      setQueueBidId(response.data.queue_id)
      setLoading(false)
    } catch (error) {
      console.log("[getQueuedBid] error-->", error)
      setqueueBidData([""])
      setQueueBidId("")
      setLoading(false)
      setNoDataFound(true)
    }
  }

  useEffect(() => {
    getQueuedBid()
  }, [])

  const handleCancelAuctionButton = (id) => {
    const queueData = queueBidData.filter((responses) => {
      return responses.application_id === id
    })
    setCancelAuctionData(...queueData)
    setCancelAuctionModal(true)
  }

  const hanleViewButtonClick = (id) => {
    const QualifyData = queueBidData.filter((responses) => {
      return responses.application_id === id
    })
    setQualifyModalData(...QualifyData)
    setQualifyModalShow(true)
  }

  const createData = (index, name, applicationid, information, startingBid) => {
    return { index, name, applicationid, information, startingBid }
  }

  const tbodyData = queueBidData.map((responses, index) => {
    return createData(
      index,
      responses.lead_name,
      responses.application_id,
      [responses.enquiry_type || "No Data"],
      responses.amount
    )
  })

  const handleStartAuctionButton = () => {
    return setStartAuctionModal(true)
  }

  const columns = [
    "Serial.No",
    "Name",
    "Application ID",
    "Qualifying Information",
    "Starting Bid",
    "Actions",
  ]
  const extra = ""
  const header = (
    <>
      <div className="d-flex justify-content-between">
        <div>Leads Queued For Auction</div>
        <Button
          className="startAuctionBtn text-white fw-bold"
          onClick={handleStartAuctionButton}
        >
          Start Auction
        </Button>
      </div>
    </>
  )

  const tbody = (
    <tbody>
      {tbodyData.map((row, index) => (
        <tr key={row.applicationid + index + 1}>
          <td className="row-index">{row.index + 1}</td>
          <td className="row-name">{row.name}</td>
          <td className="row-application-id">{row.applicationid}</td>
          <td className="infocol">
            <div className="info_row">
              {row.information}
              <span
                className="viewbtn fw-bold"
                type="link"
                onClick={() => {
                  hanleViewButtonClick(row.applicationid)
                }}
              >
                View
              </span>
            </div>
          </td>
          <td className="startingBid">
            ${parseInt(row.startingBid).toLocaleString("en-US")}
          </td>
          <td className="row-cancel">
            <Button
              className="border-light fw-bold"
              onClick={() => {
                handleCancelAuctionButton(row.applicationid)
              }}
            >
              Cancel
            </Button>
          </td>
        </tr>
      ))}
    </tbody>
  )

  const tableData = {
    header,
    loading: false,
    extra,
    columns,
    tbodyData,
    tbody,
    headerNoWrap: true,
  }

  return (
    <Prebidwrap>
      <Container className="mt-5">
        {alertmsg?.length !== 0 && (
          <Snackbar
            className="snackbar_alert"
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              variant="filled"
              color={severity}
              severity={severity}
            >
              {alertmsg}
            </Alert>
          </Snackbar>
        )}

        <CancelAuction
          {...cancelAuctionData}
          handleClick={handleClick}
          handleResponse={handleResponse}
          getQueuedBid={getQueuedBid}
          queueBidId={queueBidId}
          show={cancelAuctionModal}
          setCancelAuctionModal={setCancelAuctionModal}
          onHide={() => setCancelAuctionModal(false)}
        />

        <QualifyingInformation
          {...qualifyModalData}
          show={qualifyModalShow}
          onHide={() => setQualifyModalShow(false)}
        />

        <StartAuction
          queueBidId={queueBidId}
          queueBidData={queueBidData}
          setqueueBidData={setqueueBidData}
          setStartAuctionModal={setStartAuctionModal}
          show={startAuctionModal}
          loading={loading}
          handleClick={handleClick}
          handleResponse={handleResponse}
          setLoading={setLoading}
          onHide={() => setStartAuctionModal(false)}
        />

        {loading ? (
          <Loading />
        ) : noDataFound ? (
          <NoDataFound />
        ) : (
          <div className="prebidtable">
            <Tables {...tableData} />
          </div>
        )}
      </Container>
    </Prebidwrap>
  )
}

export default LeadQueuedForAuction
