import { Button, Modal, Form, Row, Col, Container } from "react-bootstrap"
import { api } from "../../../../Services/api"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import UIModal from ".."
import { CancelAuctionWrap } from "./style"

const CancelAuction = ({ ...props }) => {
  const { handleClick, handleResponse } = props

  const [cancelMessage, setCancelMessage] = useState("")

  const authToken = useSelector((state) => state.Auth.authToken)

  const postCancelLead = async (id) => {
    let data = JSON.stringify({
      application_id: id,
      queue_id: props.queueBidId,
      cancel_notes: cancelMessage,
    })
    try {
      const response = await api.post("/bid-leads/cancel-lead", data, {
        headers: {
          authorizationtoken: authToken,
        },
      })
      console.log("[postCancelLead] response-->", response)
      handleResponse("success", response.data.message)
      props.getQueuedBid()
      setTimeout(() => {
        props.setCancelAuctionModal(false)
        setCancelMessage("")
      }, 1000)
    } catch (error) {
      error.response.status === 401
        ? handleResponse("info", error.response?.data?.message)
        : handleResponse("error", error.response?.data?.message)
    }
  }

  const handleConfirmButton = (e) => {
    e.preventDefault()
    handleClick()
    postCancelLead(props.application_id)
  }

  return (
    <UIModal
      {...props}
      size="md"
      centered
      closeButton={false}
      header={
        <CancelAuctionWrap>
          <Modal.Title className="fw-bold pt-3 pb-0 ps-2 cancel_auction_title">
            Are you sure want to cancel the auction?
          </Modal.Title>
        </CancelAuctionWrap>
      }
    >
      <Container>
        <Row>
          <div className="fw-bold pt-0 mb-2">Notes: </div>
          <Col>
            <CancelAuctionWrap className="border rounded p-2">
              <Form onSubmit={handleConfirmButton}>
                <Form.Group className="mb-3">
                  <Form.Control
                    className="border-0 p-0 mb-2 cancel_auction_textarea"
                    as="textarea"
                    type="text"
                    placeholder="Add notes here..."
                    value={cancelMessage}
                    onChange={(e) => {
                      setCancelMessage(e.target.value)
                    }}
                  />
                  <Button size="sm" type="submit" className="confirm_button">
                    {" "}
                    Confirm{" "}
                  </Button>
                </Form.Group>
              </Form>
            </CancelAuctionWrap>
          </Col>
        </Row>
      </Container>
    </UIModal>
  )
}

export default CancelAuction
