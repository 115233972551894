import React, { useState } from "react"
import { Container } from "react-bootstrap"
import Prebidwrap from './style'
import Tables from "../../Reuseable/Tables"
import SendToQueue from "../../Reuseable/Forms/SendToQueue"
import QualifyingInformation from "../../Reuseable/UIModal/QualifyingInformation"
import Loading from "../../Reuseable/Loading/Loading"
import NoDataFound from "../../Reuseable/ErrorPage/NoDataFound"
import { Alert, Snackbar } from '@mui/material';
import { useFetch } from "../../../Hooks/useFetch"

const Prebidding = () => {

  const [qualifyModalShow, setQualifyModalShow] = useState(false)
  const [qualifyModalData, setQualifyModalData] = useState('')
  const [noDataFound, setNoDataFound] = useState(false)
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState('')
  const [alertmsg, setAlertMsg] = useState('')

  const onError = (_error) => {
    setNoDataFound(true)
  }

  const { data: responseData, isLoading, refetch } = useFetch("getDataPreBid", "/admin-lead/lead-list", () => { }, onError)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setSeverity('')
    setAlertMsg('')
    setOpen(false);
  }

  const handleResponse = (severity, responseMsg) => {
    setSeverity(severity)
    setAlertMsg(responseMsg)
  }

  const hanleViewButtonClick = (id) => {
    const QualifyData = responseData?.filter((responses) => {
      return (responses.application_id === id)
    })
    setQualifyModalData(...QualifyData)
    setQualifyModalShow(true)
  }

  function createData(
    index,
    name,
    applicationid,
    information,
    post_code,
    state,
    lead_score
  ) {
    return { index, name, applicationid, information, post_code, state, lead_score };
  }

  const tbodyData = responseData?.map((responses, index) => {

    return createData(index, responses.lead_name, responses.application_id, [responses.enquiry_type || "No Data"], responses.postcode, responses.state, responses.lead_score)
  })

  const columns = ['Serial.No', 'Name', 'Application ID', 'Qualifying Information', "Location", "Lead Score", "Actions"]
  const extra = null;
  const header = "Pre Bidding"

  const tbody = <tbody>{tbodyData?.map((row) => (
    <tr key={row.applicationid}>
      <td className="row_index" >{row.index + 1}</td>
      <td className="row_name">{row.name}</td>
      <td className="row_application_id">{row.applicationid}</td>
      <td className="infocol">
        <div className="info_row">
          {row.information}
          <span
            className="viewbtn fw-bold"
            onClick={() => { hanleViewButtonClick(row.applicationid) }}>View</span>
        </div>
      </td>
      <td className="row_post_code">{row.post_code} <br /> {row.state} </td>
      <td className="row_lead_score">{row.lead_score === "" ? "-" : row.lead_score}  </td>
      <td className="row_action">

        <SendToQueue
          application_id={row.applicationid}
          refetch={refetch}
          handleClick={handleClick}
          handleResponse={handleResponse}
          setOpen={setOpen} />
      </td>

    </tr>
  ))}
  </tbody>
  const tableData = { header, loading: false, extra, columns, tbodyData, tbody, headerNoWrap: true }

  const isNoDataFound = () => {
    return (noDataFound || responseData?.length === 0) ? true : false
  }

  return (
    <Prebidwrap>
      <Container className="mt-5">
        {alertmsg?.length !== 0 && (<Snackbar
          className='snackbar-alert'
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={4000}
          onClose={handleClose}>
          <Alert onClose={handleClose} variant="filled" color={severity} severity={severity}>
            {alertmsg}
          </Alert>
        </Snackbar>)}

        <QualifyingInformation
          {...qualifyModalData}
          show={qualifyModalShow}
          onHide={() => setQualifyModalShow(false)}
        />

        {isLoading ? <Loading /> : isNoDataFound() ? <NoDataFound /> : (
          <div className="prebidtable">
            <Tables {...tableData} />
          </div>)}

      </Container>
    </Prebidwrap>
  )
}

export default React.memo(Prebidding);  