import styled from "styled-components";

export const SendToQueueWrap = styled.div`
.start-bid {
    width: 50%;
}
.confirm_button {
    width: 120px;
    background-color: var(--orange-color);
    color: #fff;
    border: none;
}
.spinner {
    width: 1.5rem;
    height: 1.5rem;
}
#confirm:hover {
    background-color: var(--orange-hover) !important;
}
#confirm:active {
    background-color: var(--orange-active) !important;
}


`