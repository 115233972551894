const constant = {
    admin: "Hashching broker BidLead Login",
    invalid: "Invalid User",
    label_1: "Hashching Broker",
    label_2: "BidLead Login",
    label_3: "BidLead Admin Login",
};

export default constant;

export const drawerWidth = 160;
