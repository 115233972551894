import { ActionTypes } from './actions';

const userLogin = response => {
  localStorage.setItem('isLoggedIn', response.isLoggedIn);
  localStorage.setItem('token', response.authToken);
  localStorage.setItem('user', response.user)
  return {
    type: ActionTypes.LOGIN,
    payload: response
  }
}

const userLogout = response => {
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem('token');
  return {
    type: ActionTypes.LOGOUT,
    payload: response
  };
};

const userSessionTimeout = (response) => {
  return {
    type: ActionTypes.SET_SESSION_TIMEOUT,
    payload: response,
  }
}

export { userLogin, userLogout, userSessionTimeout };