import { Button, Row, Container, Spinner, Table } from "react-bootstrap"
import { api, lambdaUrl } from "../../../../Services/api"
import { useNavigate } from "react-router-dom"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import axios from "axios"
import { StartAuctionWrap } from "./style"
import UIModal from ".."
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined"
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined"

const StartAuction = ({ show, onHide, queueBidData, queueBidId, setLoading, setStartAuctionModal, loading, handleClick, handleResponse, setqueueBidData }) => {
	const navigate = useNavigate()
	const [disabled, setDisabled] = useState(false)
	const authToken = useSelector((state) => state.Auth.authToken)
	const [interstateAuction, setInterstateAuction] = useState(false)

	const handleInterstateBroker = (e) => {
		setInterstateAuction(e.target.checked)
	}

	const startEngine = async (application_id) => {
		try {
			const response = await axios.post(
				`${lambdaUrl}/1_autobidding/start-engine`,
				{ application_id },
				{
					headers: {
						authorizationtoken: authToken,
					},
				}
			)
			console.log("[startEngine] response -->", response)
		} catch (error) {
			console.log("[startEngine] error -->", error)
		}
	}

	const postLiveAuction = async () => {
		handleClick()
		setDisabled(true)
		let data = JSON.stringify({
			queue_id: queueBidId,
			interstate_auction: interstateAuction ? "yes" : "no",
		})
		try {
			const response = await api.post("/bid-leads/start-auction", data, {
				headers: {
					authorizationtoken: authToken,
				},
			})
			setLoading(false)
			queueBidData.forEach((oneData) => {
				startEngine(oneData.application_id)
			})
			handleResponse("success", response.data.message)
			navigate("/home/auction")
			setDisabled(false)
			setqueueBidData([""])
		} catch (error) {
			queueBidData.forEach((oneData) => {
				startEngine(oneData.application_id)
			})
			error?.response?.status === 401 ? handleResponse("info", error.response?.data?.message) : handleResponse("error", error.response?.data?.message)
			setDisabled(false)
			setLoading(false)
		}
	}

	const handleConfirmButton = () => {
		setLoading(true)
		setStartAuctionModal(false)
		postLiveAuction()
	}

	return (
		<StartAuctionWrap>
			<UIModal
				show={show}
				onHide={onHide}
				size="md"
				closeButton={false}
				header={
					<StartAuctionWrap>
						<div className="start_auction_interstate">
							<div className="auction_confirmation">Auction Confirmation</div>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											icon={<CheckBoxOutlineBlankOutlinedIcon />}
											checkedIcon={<CheckBoxOutlinedIcon />}
											checked={interstateAuction}
											onChange={handleInterstateBroker}
											className="interstate_checkbox"
										/>
									}
									label={<div className="invite_interstate_label">Invite Interstate broker</div>}
								/>
							</FormGroup>
						</div>
					</StartAuctionWrap>
				}
			>
				<StartAuctionWrap>
					<Container className="start_auction_container">
						<Row>
							<Table hover borderless>
								<thead>
									<tr className="text-center fw-bold">
										<th key="Name" className="text-start">
											Name
										</th>
										<th key="Application Id">Application ID</th>
										<th key="Starting Bid">Starting Bid</th>
									</tr>
								</thead>
								<tbody className="text-center">
									{queueBidData.map((oneData) => {
										return (
											<tr key={oneData.application_id} className="border-bottom">
												<td className="text-start">{oneData.lead_name}</td>
												<td>{oneData.application_id}</td>
												<td className="fw-bold">${oneData.amount}</td>
											</tr>
										)
									})}
								</tbody>
							</Table>
						</Row>

						<div className="btn_row">
							<Button size="sm" className="no_btn" onClick={onHide}>
								Cancel
							</Button>

							<Button size="sm" className="yes_btn" disabled={disabled} onClick={handleConfirmButton}>
								{loading ? <Spinner animation="border" size="sm" /> : "Confirm"}{" "}
							</Button>
						</div>
					</Container>
				</StartAuctionWrap>
			</UIModal>
		</StartAuctionWrap>
	)
}

export default StartAuction
