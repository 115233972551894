import Styled from 'styled-components';

const AuctionView = Styled.div`
.live-auction{
    font: normal normal 600 49px/59px Montserrat;
    margin: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: end;
    gap: 10px;
    color: var(--orange-color);
}
.main-head {
    position: fixed;
    margin: 40px 50px 15px 50px;
}
.interstate_text {
    white-space: nowrap;
    position: absolute;
    font-size: 0.9rem;
    font-weight: 500;
    left: 0px;
    bottom: 0px;
    translate: 0px 32px;
    color: black;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}
.interstate {
    position: relative;
}
.interstate:hover {
    .interstate_text {
        opacity: .7;
    }
}
.interstate_icon {
    width: 35px;
    height: 35px;
    object-fit: contain;
    cursor: pointer;
}
.spacing-from-head{
    margin: 80px;
}
.count-down-row{
    padding-top: 60px;
    padding-bottom: 10px;
    justify-content: space-between;
    align-items: end;
    width: inherit;
    margin: 0;
}
.time-remaining {
    font-size: 1.4em;
    padding: 8px;
    text-align: center;
    color: var(--orange-color);
}
.min, .sec {
    padding-top:7px ;
    font-size: .5em;
    font-weight: 400;
   }
.container-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.main-cont {
    background: #ffdfb5;
    padding: 30px;
    margin: 0px 50px 15px 50px;
    border-radius: 3px;
}
.live-auc-row{
    justify-content: space-between;
    align-items: center;
    width: inherit;
    margin: 0;
}
.name-col{
    display:flex;
    flex-wrap:wrap;
    padding: 10px !important;
    justify-content: space-between;
}
.amount-col {
    font-size:22px;
}
.timer-col{
    font-size:22px;
    width: 31%;
}
.app-id{
    float:right;
}
.first-line {
    background: var(--orange-color);;
    border: 1px transparent;
    padding: 5px;
    color: white;
    border-radius: 5px;
}
.first-line-amount {
    background: var(--orange-color);;
    border: 1px transparent;
    padding: 5px;
    color: white;
    border-radius: 5px;
    margin-left: 20px;
}
.bid-brokrs{
    color: var(--orange-color);
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
}
.bid-brok-col{
    display: flex;
    background: white;
    border-radius: 5px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.brok-history{
    justify-content: space-between;
    align-items: center;
    width: inherit;
    margin: 0;
}
.bid-brok-amount{
    background: var(--orange-color);;
    border: 1px transparent;
    border-radius: 5px;
    margin-left: 20px;
    background: white;
    color:var(--orange-color);;
}
.live-p {
    display: flex;
    margin: 0px !important;
    align-items: center;
    justify-content: center;
}
.leads-queue{
    font: normal normal 600 38px/47px Montserrat;
}
.view-all{
    padding-top: 6px;
    text-align:end;
    margin-bottom: 0;
}
.view-all-button {
    color: var(--orange-color);;
    font-weight: bold;
    cursor: pointer;
}
.tab-container{  
    padding:0px; 
    width: 85%;
    margin: 50px;
    border-radius: 3px; 
}
.selectbtn{
    background-color: #FFFFFF;
    color: var(--orange-color);
}
.info_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.viewbtn{
    background-color: transparent;
    color: var(--orange-color);
    border: none;
    line-height: 80%;
    cursor: pointer;
}
.infocol{
    text-align:left;
}

@media (max-width: 767px){
    .amount-col {
        margin-top: 5px; 
    }
    .main-cont {
        margin: 10px;
    }
    .interstate_text {
        display: none;
    }
    .first-line-amount {
        background: var(--orange-color);;
        border: 1px transparent;
        padding: 5px;
        color: white;
        border-radius: 5px;
        margin-left: 0px;
    }
    .bid-brok-amount{
        background: var(--orange-color);;
        border: 1px transparent;
        border-radius: 5px;
        margin-left: 0px;
        margin-top: 5px;
        background: white;
        color: var(--orange-color);;
    }
}

@media (max-width: 620px) {
    .main-head {
        position: relative;
        margin: 0px 50px 15px 50px;
    }
    .spacing-from-head{
        margin: 0;
    }
    .count-down-row {
        padding-top: 0;
    }
}
`
export default AuctionView;