import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import calenderIcon from '../../../assets/images/icons/calender-select-icon.svg'
import DatePicker from 'react-date-picker';
import React from 'react'
import styled from 'styled-components';

const CalenderSelect = ({ ...args }) => {
    return (
        <DatePickerWrap>
            <DatePicker
                calendarIcon={<img className='calender_icon' src={calenderIcon} alt="calenderIcon" />}
                className="date_picker" {...args} />
        </DatePickerWrap>
    )
}

export default CalenderSelect

const DatePickerWrap = styled.div`
    .react-date-picker{
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: #333;
        outline: none;
        background: #fff;
        padding: 2px;
        cursor: pointer;
        &::placeholder{
            color: #333;
        }
    }
    .react-date-picker__wrapper{
        width: 100%;
        height: 100%;
        border: none;
    }
    .react-date-picker__clear-button {
        opacity: .5;
    }
    .calender_icon {
        width: 20px;
        height: 20px;
        opacity: 0.5;
    }
`;