import React from 'react';
import { Alert } from 'react-bootstrap';

const AlertMessage = ({ ...props }) => {
    return (
        <>
            <Alert variant="danger">
                <Alert.Heading>{props.heading}</Alert.Heading>
                <p>
                    {props.para}
                </p>
            </Alert>
        </>
    )
}

export default AlertMessage;