import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import LayoutWrap from "./style";
import { Outlet } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import UIHeader from "../../../Reuseable/UIHeader";
import SideBar from "../SideBar";
import { useFetch } from "../../../../Hooks/useFetch";
import { queryClient } from "../../../../App";

const Main = () => {

  const [noDataFound, setNoDataFound] = useState(false)
  const panelview = useSelector((state) => state.Layout.panelview);
  const [openSideBar, setOpenSideBar] = useState(true)
  let { pathname } = useLocation()

  const isReportMenu = pathname === "/home/report"

  const isFirstTime = useRef(true)

  const onSuccess = (data) => {
    console.log("[onSuccess]  data -->", data);
    isFirstTime.current = false
    setNoDataFound(false)
  }

  const onError = (_error) => {
    isFirstTime.current = false
    queryClient.setQueriesData(["getLiveAuctionData"], null)
    setNoDataFound(true)
  }

  const { data: liveData, refetch, isLoading } = useFetch("getLiveAuctionData", "/bid-leads/live-auction", onSuccess, onError, 5000) //? calling this Api for Every 5 seconds

  useEffect(() => {
    if (!isReportMenu) {
      setOpenSideBar(false)
    } else {
      setOpenSideBar(true)
    }
  }, [isReportMenu])

  const toggleSideBar = () => {
    setOpenSideBar(!openSideBar)
  }

  return (
    <LayoutWrap className="adminDshbordBody" openSideBar={openSideBar} showSideBar={pathname === "/home/report"}>
      <div className="maincontainer-scroller">
        <UIHeader />
        <div className="container-fluid page-body-wrapper">
          <div className={"main-panel " + (panelview === "topmenu" ? "topmenu" : "")}>

            {isReportMenu && <SideBar open={openSideBar} toggleDrawer={toggleSideBar} />}

            <div className="content-wrapper">
              <Outlet context={{
                isFirstTime,
                noDataFound, isLoading, refetch, liveData,
              }} />
            </div>

          </div>
        </div>
      </div>
    </LayoutWrap>
  );
};

export default Main;
