import React from "react";
import TableWrap from './style';
import { Card, Table, Row, Col, Alert, Spinner } from "react-bootstrap";

const Tables = ({ loading, loadMore = { show: false, component: null },
  pagination = { paginate: false, number: 0 }, setPagination, currentPagination = 0, length = 0,
  header, extra, columns, tbodyData, tbody, notfound, tableClass = "common_table", headerClass = "common_header", headerNoWrap }) => {

  const totalPagination = Math.floor((length - 1) / pagination.number)

  const handleNext = () => {
    if (currentPagination === totalPagination) return
    setPagination(prev => prev + 1)
  }

  const handlePrevious = () => {
    if (currentPagination === 0) return
    setPagination(prev => prev - 1)
  }

  return (
    <TableWrap>
      <Card className="border-0 mb-3 table-view-card">
        <Card.Body>
          {!loading ?
            <>
              <Row sm={12} className="mb-2 p-0 pb-1 table_header"> <div className={headerClass}>{header}</div> </Row>
              <Row>
                <div className={tableClass} >
                  {extra ? <Col sm={12} className="mb-2"> {extra} </Col> : <></>}
                  <Col md={12}>
                    {tbodyData?.length > 0
                      ? <TableWrap>
                        <Table className="mb-0" striped responsive bordered hover size="sm md lg xl xxl ">
                          <thead>
                            <tr>{columns
                              .map((item, i) => { return <th className={`p-2 ${headerNoWrap ? "text-nowrap" : ""}`} key={i}>{item}</th> })
                            }</tr>
                          </thead>
                          {tbody}
                        </Table>

                        {
                          loadMore?.show ? loadMore?.component : null
                        }

                        {pagination.paginate && length > pagination.number ?
                          <div className="pagination">
                            <button className={`previous ${currentPagination === 0 ? "disabled" : ""}`} onClick={handlePrevious}>Previous</button>
                            <button className={`next ${currentPagination === totalPagination ? "disabled" : ""}`} onClick={handleNext}>Next</button>
                          </div>
                          : <></>}

                      </TableWrap>
                      :
                      <Alert className="text-center mt-2" variant="primary"> {notfound} </Alert>
                    }
                  </Col>
                </div>
              </Row>
            </>
            :
            <div className='text-center mt-5 mb-5'>
              <Spinner animation="border" variant='dark' />
            </div>
          }
        </Card.Body>
      </Card>
    </TableWrap>
  );


}

export default Tables;
