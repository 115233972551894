import { ActionTypes } from "./actions";

const intialState = {
  result: 'won',
  tobeFiltered: {
    state: null,
    broker: null,
  },
  reportFilter: {
    state: null,
    broker: null,
  },
  dateRange: {
    startDate: null,
    endDate: null,
  }
};

export const GlobalReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_RESULT:
      return { ...state, result: payload };
    case ActionTypes.SET_TOBE_FILTERED:
      return { ...state, tobeFiltered: payload };
    case ActionTypes.SET_REPORT_FILTER:
      return { ...state, reportFilter: payload };
    case ActionTypes.SET_DATE_RANGE:
      return { ...state, dateRange: payload };
    case ActionTypes.RESET:
      return intialState;
    default:
      return state;
  }
};
