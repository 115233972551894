import React from 'react'
import moment from "moment";
import { Col, Container, Row } from 'react-bootstrap';
import ViewAll from '../../Reuseable/UIModal/ViewAll';
import { useState } from 'react';
import { useFetch } from '../../../Hooks/useFetch';

const Lot = ({ index, live }) => {

    const [viewAllModal, setViewAllModal] = useState(false)

    const { data: viewAllData, isLoading: viewAllLoading, refetch: refetchViewAll } = useFetch(["getViewAllData", live.application_id], `/bid-leads/live-auction/details/${live.application_id}`, () => { }, () => { }, 5000, viewAllModal)

    const handleViewAllButton = () => {
        refetchViewAll()
        setViewAllModal(true)
    }

    return (
        <div className="container-wrapper" key={index}>
            <Container className='main-cont'>
                <Row>
                    <div className="fw-bold fs-5 pb-2"> Lot {index + 1}</div>
                </Row>

                <Row className="live-auc-row">
                    <Col className="bid-brok-width" md={9}>
                        <Row className="justify-content-evenly">
                            <Col className="name-col first-line " >
                                <p className="name live-p">{live.lead_name}</p>
                                <p className="app-id live-p">Application ID: {live.application_id}</p>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={3} className='ps-0' >
                        <Row className="first-line-amount amount-col">
                            <p className="live-p">${parseInt(live.base_amount).toLocaleString("en-US") === "NaN" ? "0" : parseInt(live.base_amount).toLocaleString("en-US")}</p>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    {(live.broker_bidding_data?.length === 0) ? (<p className="bid-brokrs"><b>No Bidding brokers</b></p>) : (<p className="bid-brokrs"><b>Bidding brokers</b></p>)}
                </Row>

                {live.broker_bidding_data?.map((broker, index) => {
                    return (
                        <div key={broker.broker_name + index}>
                            <Row className="brok-history pb-2">
                                <Col className="bid-brok-col bid-brok-width" md={9}>
                                    <p className="live-p p-2"> {broker.broker_name} </p>
                                    <p className="live-p p-2 fw-semibold"> {broker.bid_type === "auto" ? "Auto Bid" : broker.bid_type === "manual" ? "Manual Bid" : ""} </p>
                                    <p className="live-p p-2"> {moment(broker.bid_date_time).format('h:mm:ss a')} </p>
                                </Col>

                                <Col xs={12} md={3}>
                                    <Row>
                                        <Col className="bid-brok-amount">
                                            <p className="live-p p-2"><b>${parseInt(broker.bid_amount).toLocaleString("en-US") === "NaN" ? "0" : parseInt(broker.bid_amount).toLocaleString("en-US")}</b></p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>)
                })}

                {(live.broker_bidding_data?.length !== 0) && (
                    <Row>
                        <p className="view-all">
                            <span className='view-all-button' onClick={() => { handleViewAllButton(live.application_id) }}>View All</span>
                        </p>
                    </Row>
                )}

            </Container>

            <ViewAll
                viewAllLoading={viewAllLoading}
                viewAllData={viewAllData}
                show={viewAllModal}
                onHide={() => setViewAllModal(false)}
            />
        </div>
    )
}

export default Lot