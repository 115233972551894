import Styled from 'styled-components';

const Prebidwrap = Styled.div`
.selectbtn{
    background-color: #FFFFFF;
    color: var(--orange-color);
}
.selectbtn:hover {
    color: var(--orange-hover) !important;
}
.info_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.viewbtn{
    background-color: transparent;
    color: var(--orange-color);
    border: none;
    line-height: 80%;
    cursor: pointer;
}
.snackbar-alert {
    padding-top: 100px;
}
.row_index {
    width: 5% !important;
}
.row_name {
    width: 10% !important;
}
.row_application_id {
    width: 15% !important;
}
.infocol{
    width: 20% !important;
}
.row_post_code {
    width: 20% !important;
}
.row_lead_score {
    width: 10% !important;
}
.row_action {
    width: 20% !important;
}
`;

export default Prebidwrap;