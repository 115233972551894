import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Link, NavLink } from 'react-router-dom'
import HeaderWrap from './style'
import logoutB from './logoutB.svg'
import './menu.css'
import { userLogout } from '../../../redux/Auth/actionCreator'
import BurgerMenuButton from '../UIButton/AnimationButton'
import { Button, Spinner } from 'react-bootstrap'
import { api } from '../../../Services/api'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import BidLead from '../../../assets/images/icons/BidLead.svg'
import { useFetch } from '../../../Hooks/useFetch'
import { queryClient } from '../../../App'
import { resetGlobal } from '../../../redux/Global/actionCreator'

const UIHeader = () => {
	const user = useSelector((state) => state.Auth.user)
	const authToken = useSelector((state) => state.Auth.authToken)
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [logoutLoader, setLogoutLoader] = useState(false)
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const liveData =
		queryClient.getQueryData(['getLiveAuctionData']) ?? null

	const AdminUser = user === 'Admin'

	const navlink = AdminUser ? [
		{ item: 'Pre Bidding', navLink: 'pre-bidding' },
		{ item: 'Next Auction', navLink: 'next-auction' },
		{
			item:
				(liveData !== null) > 0 ? (
					<>
						<span className="blinking-dot"></span> Live Auction
					</>
				) : (
					'Auction'
				),
			navLink: 'auction',
		},
		{ item: 'Report', navLink: 'report' },
	] : [
		{
			item:
				(liveData !== null) > 0 ? (
					<>
						<span className="blinking-dot"></span> Live Auction
					</>
				) : (
					'Auction'
				),
			navLink: 'auction',
		}
	]

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const logoutUser = () => {
		let result = {
			isLoggedIn: false,
			authToken: '',
			user: '',
		}
		dispatch(userLogout(result))
		navigate('/admin')
	}

	const postLogoutUser = async () => {
		setLogoutLoader(true)
		try {
			const response = await api.post(
				'/admin-auth/logout',
				{},
				{
					headers: {
						authorizationtoken: authToken,
					},
				}
			)
			dispatch(resetGlobal())
			setLogoutLoader(false)
			console.log('[postLogoutUser] response--> ', response)
			logoutUser()
		} catch (error) {
			setLogoutLoader(false)
			console.log('[postLogoutUser] error--> ', error)
		}
	}

	const { data: currentLoggedIn } = useFetch(
		'currentLoggedIn',
		'/admin-lead/login-logs',
		() => { },
		() => { },
		60000
	) //? calling this API for Every 1 minute

	const handleLogoClick = () => {
		return navigate(navlink[0].navLink)
	}

	return (
		<HeaderWrap>
			<nav className="navbar navbar-expand-md navbar-light bg-white shadow position-fixed w-100 z-index-navbar">
				<div className="container-fluid pe-0">
					<div className="menu-display">
						<div className="hide-menu">
							<div className="position-relative">
								<div>
									<div
										className="border-0 p-0"
										id="basic-button"
										aria-controls={
											open ? 'basic-menu' : undefined
										}
										aria-haspopup="true"
										aria-expanded={
											open ? 'true' : undefined
										}
										onClick={handleClick}
									>
										<BurgerMenuButton open={open} />
									</div>

									<Menu
										id="basic-menu"
										className="basic-menu"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										MenuListProps={{
											'aria-labelledby': 'basic-button',
										}}
									>
										<div className="position-relative">
											{navlink?.map((nav) => {
												return (
													<NavLink
														key={nav.item}
														className=" navbar-brand nav-link menu-item"
														type="button"
														to={nav.navLink}
													>
														<MenuItem
															className="fw-bold blue-theme"
															onClick={
																handleClose
															}
														>
															<div className="text-capitalize">
																{nav.item}
															</div>
														</MenuItem>
													</NavLink>
												)
											})}

											<MenuItem className="fw-bold blue-theme">
												<div className="menu-item">
													currently Logged In :{' '}
													{currentLoggedIn?.total_today_logged_in ??
														0}
												</div>
											</MenuItem>

											<MenuItem
												className="fw-bold blue-theme "
												to="#"
												type="button"
												onClick={handleClose}
												data-bs-toggle="modal"
												data-bs-target="#exampleModal"
												data-bs-placement="bottom"
												title="Logout"
											>
												<div className="menu-item">
													{logoutLoader ? (
														<Spinner
															animation="border"
															variant="dark"
														/>
													) : (
														'Logout'
													)}
												</div>
											</MenuItem>
										</div>
									</Menu>
								</div>
							</div>
						</div>
					</div>

					<div className="navbar-item">
						<div className="d-inline-block text-truncate">
							<Button
								className="bidlead_admin_logo_button"
								onClick={handleLogoClick}
							>
								<img
									className="bidlead_admin_logo"
									src={BidLead}
									alt="menuIcon"
								></img>
								{AdminUser && <div className="position-absolute">
									<div className="logo-admin-indication">
										Admin
									</div>
								</div>}
							</Button>
						</div>
					</div>

					<div
						className="collapse navbar-collapse justify-content-start"
						id="navbarNavAltMarkup"
					>
						{navlink?.map((nav, index) => {
							return (
								<ul
									key={index}
									className={`navbar-nav ${index === navlink.length - 1
										? 'me-auto'
										: ''
										}`}
								>
									<li className={`nav-item mx-2`}>
										<NavLink
											className={`navbar-brand nav-link ${AdminUser ? "" : "dont_show_active"} position-relative`}
											to={nav.navLink}
										>
											<div className="text-capitalize">
												{nav.item}
											</div>
										</NavLink>
									</li>
								</ul>
							)
						})}

						<ul className="navbar-nav login-history">
							<li className="nav-item mx-2 current-users">
								currently Logged In :{' '}
								{currentLoggedIn?.total_today_logged_in ?? 0}
							</li>
						</ul>

						<ul className="navbar-nav">
							<li className="nav-item">
								<Link
									className="log pt-1 nav-link"
									to="#"
									type="button"
									data-bs-toggle="modal"
									data-bs-target="#exampleModal"
									data-bs-placement="bottom"
									title="Logout"
								>
									<img
										src={logoutB}
										height={29}
										width={29}
										alt="Logout"
									></img>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</nav>

			<div
				className="modal fade"
				id="exampleModal"
				aria-labelledby="exampleModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header border-0">
							<h5
								className="modal-title fw-bold"
								id="exampleModalLabel"
							>
								Logout User
							</h5>
						</div>
						<div className="modal-body text-center fw-normal fs-6">
							Are you sure want to Logout?
						</div>
						<div className="modal-footer justify-content-center border-0 mx-4 ">
							<button
								type="button"
								className="btn btn-secondary m-4"
								id="close-button"
								data-bs-dismiss="modal"
							>
								Close
							</button>
							<button
								className="btn m-4"
								onClick={postLogoutUser}
								type="button"
								data-bs-dismiss="modal"
								id="yes-button"
							>
								Logout
							</button>
						</div>
					</div>
				</div>
			</div>
		</HeaderWrap>
	)
}

export default UIHeader
