import styled from "styled-components";

export const ReportWrap = styled.div`
display: flex;
flex-direction: column;
height: auto;
padding: 0px 10px 0px 20px;
.report_heading {
    font: normal normal bold 24px/29px Montserrat;
    color: var(--orange-color);
}
.report_row {
    white-space: nowrap !important;
}
.report_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    margin-top: 10px;
}
.report_title {
    font: normal normal bold 20px Montserrat;
}
.MuiSvgIcon-root { 
    width: 20px;
    height: 20px;
}
.report_won_bid {
    color: var(--orange-color) !important;
}
.dropdown-toggle::after {
  display: none !important; 
}
button.report_csv_download {
    background: var(--orange-color);
    border-radius: 5px;
    border: none;
    font: normal normal bold 16px Montserrat;
    color: #FFFFFF;
    padding: 10px 20px;
}
.row_Auction_Id {
    width: 10% !important;
    font-weight: 600;
}
.filter_container {
    width: 240px;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
table {
    tbody {
        tr {
        td.report_row {
            white-space: nowrap !important;
        }
    }
}
}

`;