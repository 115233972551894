import Styled from "styled-components";
import { drawerWidth } from "../../../../config/constant";


const LayoutWrap = Styled.div`
        code {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h1 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h2 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h3 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h4 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h5 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h6 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        .main-panel{
            margin-top: 10px;
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(100vh - 64px);
            display: flex;
            flex-direction: row;
            min-width: 0;
            z-index: 11;
            width: 100%;
            flex-grow: 1;
        }
        .main-panel.topmenu {
            margin-left: 0px;
        }
        .page-body-wrapper {
            height: auto;
            overflow: hidden;
            overflow-y: hidden;
            padding-top: 50px;
            display: flex;
            flex-direction: row;
            padding-left: 0;
            padding-right: 0;
        }
        .content-wrapper {
            padding: 15px;
            width: calc(100% - ${({ openSideBar }) => openSideBar ? drawerWidth : ({ showSideBar }) => showSideBar ? 64 : 0}px);
        }
        a:active,
        a:hover,
        a:focus {
        }
        .text-orange
        {
            color:${({ theme }) => theme["orange-color"]};
        }
        .text-orange:hover
        {
            color: ${({ theme }) => theme["orange-hover"]};
        }
        .btn-close {
            &:focus {
                box-shadow: inherit;
            }
        }
        .btn 
        {
            &.btn-orange {
                color: #fff;
                background-color: ${({ theme }) => theme["orange-color"]};
                border-color: ${({ theme }) => theme["orange-color"]};
                margin: 0 auto;
                font-size: 16px;
                &:hover {
                    color: #fff;
                    background-color: ${({ theme }) => theme["orange-hover"]};
                border-color:${({ theme }) => theme["orange-hover"]};
                }
            }
        }
        .btn-grey {
            color: #212529;
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            margin: 0 auto;
            font-size: 16px;
            &:hover {
                color: #212529;
            }
        }
        .form-check-input {
            &:checked {
                background-color: ${({ theme }) => theme["orange-color"]};
                border-color: ${({ theme }) => theme["orange-color"]};
            }
            &:focus {
                border-color: ${({ theme }) => theme["orange-color"]};
                outline: 0;
                box-shadow: inherit;
            }
            &:checked[type=radio] {
                &:focus {
                    border-color: ${({ theme }) => theme["orange-color"]};
                }
            }
            cursor: pointer;
            border: 2px solid #0c0c0c40;
        }
        .form-check-input[type=checkbox] {
            border-radius: 2px;
        }
        .card {
            border: 0 solid #dedede;
            border-radius: 8px;
        }
        .toggle {
        padding-top: 50px;
        padding-left: 20px;
        }
        .menu{
            color: #FA9001;
        }
        .touchArea{
            margin-right:1.25em;
        }
        .mobSideBar 
        {
            .mobSideBar .sidebar.sidebarMain {
                width: 60px;
            }   
            .sidebarMain {
                .serchFieldMain {
                  margin: 0 17px;
                  input.form-control {
                  }
                }
              }
              .sidebar {
                .nav {
                  .dropmenuitems {
                    .nav-link {
                      display: none;
                    }
                  }
                  .nav-link {
                    padding: 6px 10px 6px 17px;
                  }
                }
              }
            .sidebarMainLinks {
                width: 60px;
              }
              .sidebar.sidebarInerLinks {
                width: 60px;
              }
              .sidebar.sidebarMain {
                width: 60px;
              }
              .listArowIcon {
                display: none;
              }
              .navItemArrow {
                display: none;
              }
              .nav-link {
                span.dsktopView {
                  display: none;
                }
              }
        }    
        
        @media (max-width: 575px) {
            .main-panel {
                margin-left: 0;
            }
            .mobSideBar .main-panel {
                margin-left: 0;
            }
            .sidebar {
                width: 0;
            }
            .toggle {
                padding-top: 20px;
                padding-left: 5px;
            }
            .mobSideBar .sidebar.sidebarMain {
                width: 0;
            }
            .mobSideBar .sidebarMainLinks {
                width: 160px;
            }
            .mobSideBar .sidebar.sidebarMain {
                width: 160px;
                z-index: 111;
            }
            .mobSideBar .nav-link span.dsktopView {
                display: inline-block;
            }
            
            .sidebarMain .serchFieldMain
            {
                display: none;
            }
            .mobSideBar .sidebarMain .serchFieldMain {
                display: inline-block;
            }
        }
`;

export default LayoutWrap;
