import Styled from "styled-components"

const Prebidwrap = Styled.div`
.startAuctionBtn {
    background: var(--orange-color); ;
    border: none;
}
.startingBid {
    font-weight: 500;
    width: 15%;
}
.snackbar_alert {
    padding-top: 100px !important;
}
.info_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.viewbtn{
    background-color: transparent;
    color: var(--orange-color);
    border: none;
    line-height: 80%;
    cursor: pointer;
}
.row-index {
    width: 10% !important;
}
.row-name {
    width: 17% !important;
}
.row-application-id {
    width: 18% !important;
}
.infocol{
    text-align:left;
    width: 25% !important;
}
.row-cancel {
    width: 15% !important;
}

`

export default Prebidwrap
