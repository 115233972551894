import styled from "styled-components";

export const CancelAuctionWrap = styled.div`

.snack_bar {
    padding-top: 100px;
}
.cancel_auction_title {
    font: normal normal 600 16px Montserrat;
    color: var(--orange-color);
}
.cancel_auction_textarea {
    resize: none;
}
.confirm_button {
    float: right;
    background: var(--orange-color); 
    border: none;
    margin-top: 1.5rem;
}

`