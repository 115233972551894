import Styled from "styled-components";


const LoginWrap = Styled.div`

        
        *,*::before,*::after {
            box-sizing: border-box;
        }

        .loginPageContent {
            overflow: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }


        .container-width {
            float: right;           
            width: 540px;
            box-shadow: 0px 0px 8px #0000004F;
            border-radius: 10px;
            opacity: 1;
        }
        .loginFormScreens {
            margin: 25px;
            border-radius: 10px;
        }
        .logoimg {
            max-height: 50px;
            padding-top: 0px;
        }
        .formScreenUser {
            color: #111247;
            font-weight: 600;
        }
        .formScreenTitle {
            color: #555;
            font-weight: 600;
        }
        .forgotLink {
            color:  ${({ theme }) => theme["orange-color"]};
            font-size: 14px;
            font-weight: 500;
            &:hover {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:active {
                color: ${({ theme }) => theme["orange-hover"]};
            }
            &:focus {
                color: ${({ theme }) => theme["orange-hover"]};
            }
        }
        .loginFormScreens {
            .form-control {
                padding: 10px 20px;
                font-size: 15px;
            }
        }
        form {
            label {
                color: ${({ theme }) => theme["gray-color"]};
            }
        }



        @media only screen and (max-width:350px){
            .container-width {
               width: 300px !important;
            }
            .loginPageContent {
               min-width: 320px !important;               
            }
            .card{
                padding: 0px !important;
            }
            .loginFormScreens {
                margin: 10px !important;
            }
            
        }

        @media only screen and (max-width:800px) {
            .container-width {
                float: none;
                display: flex;
                justify-content: center !important;
                width: 320px;

            }

            .loginPageContent {
                min-width: 350px;
                max-height: 100vh;
                display: flex;
                align-items: center;
            }
            .loginFormScreens {
                margin: 20px;
            }
                
         }
         @media only screen and (min-width:1024px) {
            .container-width {
                position: relative;
                left: 20%;
                padding: 0;

            }
         }
        
        
`;

export default LoginWrap;
